import React from 'react'
import MetaData from '../components/MetaData'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container, Col, Row } from 'react-bootstrap'

import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

import PageWrapper from '../components/PageWrapper'
import { Title, Text, Section, Box, Button } from '../components/Core'

import theme from '../utils/theme'
import Hero from '../sections/common/Hero'

// import LsDarkLogo from '../assets/image/svg/ls-logo-dark.inline.svg'
// import LsLightLogo from '../assets/image/svg/ls-logo-light.inline.svg'

// import ConigyLightLogo from '../assets/image/svg/CoinigyLogoBlack.inline.svg'
// import ConigyDarkLogo from '../assets/image/svg/CoinigyLogoBlue.inline.svg'
import aboutUsImg from '../assets/image/svg/aboutUs.svg'


const RenderItems = ({ items }) => {
  const mb = { marginBottom: '20px' }
  return Array.isArray(items) ? items.map((item, index) => (
    <label style={mb} key={index}>
      {item}
    </label>
  )) : '';
}
const ImageShadow = styled(Box)`
  img {
    border-radius: 10px;
    -webkit-filter: drop-shadow(
      ${({ theme }) => `0 52px 42px ${theme.colors.shadow}`}
    );
    filter: drop-shadow(${({ theme }) => `0 52px 42px rgba(0,0,0,0.3)`});
  }
`

const AboutUs = () => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  // Email Input Style : Light.
  const inputLight = {
    color: theme.colors.darkGray,
    background: theme.colors.light,
    placeholder: theme.colors.lightGray,
  }

  // Email Input Style : Dark.
  const inputDark = {
    color: theme.colors.light,
    background: theme.colors.darkGray,
    placeholder: theme.colors.lightGray,
  }

  // Theme Colors Options.
  const textColor = userDarkMode ? theme.colors.light : theme.colors.dark
  const linkColor = userDarkMode
    ? theme.colors.primaryLight
    : theme.colors.primary
  const secondaryBackground = userDarkMode
    ? theme.colors.dark
    : theme.colors.light
  const inputColors = userDarkMode ? inputDark : inputLight

  const { t, i18n, ready } = useTranslation(['about'], { useSuspense: false })

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  // Urls.
  const COINIGY_URL = `https://www.coinigy.com/`
  const LIGHTSPEED_URL = `https://www.lightspeed.com/`
  const LIDERSHIP_URL = `/${lang}/about/leadership`

  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        {/* Built for the active trader : Section */}
        <Hero
          className="pb-3 mt-5"
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('builtActiveTitle')}
          firstP={t('builtActiveContent')}
          img={true}
          imgSrc={aboutUsImg}
        />

        {/* History of Lightspeed : Section */}
        <Section bg={secondaryBackground}>
          <Container className="pb-5">
            <Row>
              <Col lg="4">
                <Title variant="highlight" color={textColor}>
                  {t('lightspeedHistoryTitle')}
                </Title>
              </Col>

              <Col lg="8">
                <Text color={textColor} className="pb-4">
                  {t('lightspeedHistoryContent')}
                </Text>

                <Text color={textColor} className="pb-2">
                  {t('lightspeedHistoryExtra')}
                </Text>

                {/* <SvgMode
                  width="248px"
                  height="110px"
                  Dark={LsDarkLogo}
                  Light={LsLightLogo}
                  isDarkMode={userDarkMode}
                /> */}

                <Row className="pb-4">
                  <a target="_blank" href={LIGHTSPEED_URL}>
                    <Text variant="link" color={linkColor}>
                      {t('lightspeedHistoryLink')}
                    </Text>
                  </a>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>

        {/* History of Coinigy : Section */}
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row className="pb-4">
              <Col lg="4">
                <Title variant="highlight" color={textColor}>
                  {t('conigyHistoryTitle')}
                </Title>
              </Col>

              <Col lg="8">
                <Text color={textColor}>
                  <RenderItems items={t('conigyHistoryContent')} />
                </Text>

                <Row className="pb-4">
                  <a target="_blank" href={COINIGY_URL}>
                    <Text variant="link" color={linkColor}>
                      {t('conigyHistoryLink')}
                    </Text>
                  </a>
                </Row>

                {/* <SvgMode
                  width="205px"
                  height="130px"
                  Dark={ConigyDarkLogo}
                  Light={ConigyLightLogo}
                  isDarkMode={userDarkMode}
                /> */}
              </Col>
            </Row>
          </Container>
        </Section>

        {/* The rest is history : Section */}
        <Section bg={secondaryBackground}>
          <Container>
            <Row className="pb-4">
              <Col lg="4">
                <Title variant="highlight" color={textColor}>
                  {t('restIsHistoryTitle')}
                </Title>
              </Col>

              <Col lg="8">
                <Text color={textColor}>
                  {t('restIsHistoryContent')}
                </Text>

                <Text variant="card" color={textColor} className="pb-3 pt-3">
                  {t('restIsHistoryExta')}
                </Text>

                <Col
                  lg="12"
                  xl="8"
                  className="pb-5 pt-5"
                  style={{ paddingLeft: 0 }}
                >
                  <a
                    aria-label={t('signup')}
                    href={process.env.LS_SIGNUP}
                    rel="noopener noreferrer"
                  >
                    <Button
                      type="submit"
                      style={{ fontSize: '16px' }}
                      background={'#124D95'}
                    >
                      {t('getStarted')}
                    </Button>
                  </a>
                </Col>

                <Row className="pb-4">
                  <Link to={LIDERSHIP_URL}>
                    <Text variant="link" color={linkColor}>
                      {t('restIsHistoryLink')}
                    </Text>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default AboutUs
